import {
  WppActionButton,
  WppIconChevron,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppTag,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { showRequestTenantModal } from 'components/modal/requestTenantModal/RequestTenantModal'
import { ANALYTICS_EVENTS } from 'constants/analytics'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import styles from 'layout/header/Header.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { getAnalyticsEventPayload, trackAnalytics } from 'utils/analytics'

export const TenantSwitcher = () => {
  const { t } = useTranslation()
  const { defaultTenant } = useOsRoot()
  const { currentTenant } = useCurrentTenantData()
  const { availableTenants, requestableTenants, userDetails } = useOtherTenantsAndUserData()
  const [search, setSearch] = useState('')

  const refTenantSearch = useRef<HTMLWppInputElement>(null)

  const sortedTenants = useMemo(() => {
    const tenants = availableTenants
      .filter(tenant => tenant.id !== userDetails.preferredTenantId)
      .sort((a, b) => a.name.localeCompare(b.name))

    return search ? tenants.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())) : tenants
  }, [availableTenants, userDetails.preferredTenantId, search])

  const showTenantList = !!sortedTenants.length || !!requestableTenants.length || search

  const preferredTenant = useMemo(
    () => availableTenants.find(tenant => tenant.id === userDetails.preferredTenantId),
    [availableTenants, userDetails.preferredTenantId],
  )

  const setSearchDebounced = useDebounceFn((search?: string) => setSearch(search?.trim() || ''), Delay.Search)

  if (!showTenantList) {
    return null
  }

  return (
    <WppMenuContext
      dropdownConfig={{
        popperOptions: {
          strategy: 'fixed',
        },
      }}
    >
      <WppActionButton
        className={styles.tenantMenuTriggerButton}
        slot="trigger-element"
        variant="secondary"
        data-testid="tenant_select"
        onClick={() => {
          setSearch('')
          refTenantSearch.current?.setValue('')
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: ANALYTICS_EVENTS.LIST_OF_TENANTS.ACTIONS.LIST,
          })
        }}
      >
        <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-start" direction="down" />
      </WppActionButton>

      <div>
        {(sortedTenants.length > 0 || search) && (
          <>
            {(sortedTenants.length > 3 || search) && (
              <WppMenuGroup withDivider>
                <WppListItem disabled className={styles.searchInputItem}>
                  <WppInput
                    ref={refTenantSearch}
                    slot="label"
                    size="s"
                    type="search"
                    className={styles.searchInput}
                    onWppChange={({ detail }) => setSearchDebounced(detail.value)}
                    placeholder={t('os.user_profile.search_workspace_placeholder')}
                  />
                </WppListItem>
              </WppMenuGroup>
            )}

            {preferredTenant && (
              <WppMenuGroup className={styles.tenantMenuGroup} withDivider>
                <WppListItem
                  key={preferredTenant.id}
                  checked={preferredTenant.id === currentTenant.id}
                  linkConfig={
                    preferredTenant.id !== currentTenant.id
                      ? {
                          href: process.env.DEV ? '/' : preferredTenant.homeUrl,
                        }
                      : undefined
                  }
                  onClick={() =>
                    trackAnalytics({
                      type: AnalyticsActionType.page,
                      payload: getAnalyticsEventPayload(
                        ANALYTICS_EVENTS.LIST_OF_TENANTS.PAGE.OPEN_TENANT,
                        preferredTenant.name,
                      ),
                    })
                  }
                >
                  <Avatar
                    slot="left"
                    name={preferredTenant.name}
                    src={preferredTenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
                  />

                  <span slot="label">{preferredTenant.name}</span>

                  <WppTag slot="right" label={t('os.common.default')} variant="neutral" />
                </WppListItem>
              </WppMenuGroup>
            )}

            <WppMenuGroup className={styles.tenantMenuGroup} withDivider={!!requestableTenants.length}>
              {sortedTenants.length ? (
                sortedTenants.map(tenant => (
                  <WppListItem
                    key={tenant.id}
                    checked={tenant.id === currentTenant.id}
                    linkConfig={
                      tenant.id !== currentTenant.id
                        ? {
                            href: process.env.DEV ? '/' : tenant.homeUrl,
                          }
                        : undefined
                    }
                    onClick={() =>
                      trackAnalytics({
                        type: AnalyticsActionType.page,
                        payload: getAnalyticsEventPayload(
                          ANALYTICS_EVENTS.LIST_OF_TENANTS.PAGE.OPEN_TENANT,
                          tenant.name,
                        ),
                      })
                    }
                  >
                    <Avatar
                      slot="left"
                      name={tenant.name}
                      src={tenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
                    />
                    <span slot="label">{tenant.name}</span>
                  </WppListItem>
                ))
              ) : (
                <WppListItem disabled>
                  <span slot="label">{t('os.common.select.empty_text')}</span>
                </WppListItem>
              )}
            </WppMenuGroup>
          </>
        )}
        {!!requestableTenants.length && (
          <WppListItem
            onWppChangeListItem={() =>
              showRequestTenantModal({
                title: t('os.request_access_modal.workspace.access_another_os'),
                tenants: requestableTenants,
              })
            }
          >
            <span slot="label">{t('os.header.tenant_selection.request_access')}</span>
          </WppListItem>
        )}
      </div>

      {/* This limits the min-width of the list, but allows other items to expand further */}
      <div className={styles.menuMinWidthLimiter} />
    </WppMenuContext>
  )
}
